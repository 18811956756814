var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Stack } from '@mui/material';
import AsyncSelect from '../../../../components/AsyncSelect';
// components
import Iconify from '../../../../components/iconify';
import usePermission from '../../../../hooks/usePermission';
import { getAllOffices } from '../../../../redux/slices/offices/actions';
import { getUsers } from '../../../../redux/slices/users/actions';
import { dispatch, useSelector } from '../../../../redux/store';
export default function BooksCashDeskPaymentsToolbar(_a) {
    var _this = this;
    var isFiltered = _a.isFiltered, onResetFilter = _a.onResetFilter, cashedBy = _a.cashedBy, setCashedBy = _a.setCashedBy, filterOffice = _a.filterOffice, setFilterOffice = _a.setFilterOffice, cashedTo = _a.cashedTo, setCashedTo = _a.setCashedTo, 
    // onExport,
    //
    startDate = _a.startDate, endDate = _a.endDate, 
    //
    onChangeStartDate = _a.onChangeStartDate, onChangeEndDate = _a.onChangeEndDate, 
    //
    open = _a.open, onOpen = _a.onOpen, onClose = _a.onClose, 
    //
    isSelected = _a.isSelected, isError = _a.isError, shortLabel = _a.shortLabel;
    var isSuperAdmin = usePermission().isSuperAdmin;
    var status = useSelector(function (state) { return state.common; }).status;
    var handleChangeStartDate = function (newValue) {
        onChangeStartDate(newValue);
    };
    var handleChangeCloseDate = function (newValue) {
        onChangeEndDate(newValue);
    };
    return (_jsxs(Stack, __assign({ spacing: 2, alignItems: "center", direction: {
            xs: 'column',
            sm: 'row',
        }, sx: { px: 2.5, py: 3 } }, { children: [_jsx(AsyncSelect, { name: "cashedBy", label: "select the Cashed By", placeholder: "Choose the Cashed By", fetchData: function (params) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, dispatch(getUsers(params))
                                    .unwrap()
                                    .then(function (res) { return res; })];
                            case 1: return [2 /*return*/, _a.sent()];
                        }
                    });
                }); }, getOptionLabel: function (option) { return (option === null || option === void 0 ? void 0 : option.name) || (option === null || option === void 0 ? void 0 : option.email); }, getOptionValue: function (option) { return option === null || option === void 0 ? void 0 : option._id; }, sx: {
                    padding: '.55rem 1rem',
                    minWidth: 200,
                }, value: cashedBy, onChange: function (value) {
                    setCashedBy(value);
                }, customContainerStyles: { flexGrow: 1 } }), _jsx(AsyncSelect, { name: "cashedTo", label: "select the Cashed To", placeholder: "Choose the Cashed To", fetchData: function (params) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, dispatch(getUsers(params))
                                    .unwrap()
                                    .then(function (res) { return res; })];
                            case 1: return [2 /*return*/, _a.sent()];
                        }
                    });
                }); }, getOptionLabel: function (option) { return (option === null || option === void 0 ? void 0 : option.name) || (option === null || option === void 0 ? void 0 : option.email); }, getOptionValue: function (option) { return option === null || option === void 0 ? void 0 : option._id; }, sx: {
                    padding: '.55rem 1rem',
                    minWidth: 200,
                }, value: cashedTo, onChange: function (value) {
                    setCashedTo(value);
                }, customContainerStyles: { flexGrow: 1 } }), isSuperAdmin && (_jsx(AsyncSelect, { name: "office", label: "Choose the Office", placeholder: "Choose the Office", fetchData: function (params) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, dispatch(getAllOffices(params))
                                    .unwrap()
                                    .then(function (res) { return res; })];
                            case 1: return [2 /*return*/, _a.sent()];
                        }
                    });
                }); }, getOptionLabel: function (option) { return (option === null || option === void 0 ? void 0 : option.name) || (option === null || option === void 0 ? void 0 : option.address); }, getOptionValue: function (option) { return option === null || option === void 0 ? void 0 : option._id; }, sx: {
                    padding: '.55rem 1rem',
                    minWidth: 200,
                }, onChange: function (value) { return setFilterOffice(value); }, value: filterOffice, customContainerStyles: { flexGrow: 1 } })), isFiltered && (_jsx(Button, __assign({ color: "error", sx: { flexShrink: 0 }, onClick: onResetFilter, startIcon: _jsx(Iconify, { icon: "eva:trash-2-outline" }) }, { children: "Clear" })))] })));
}
